import { Paper, Tabs, Grid, Tab, Typography, Box, useMediaQuery, Menu, MenuItem } from '@mui/material';
import './App.css';
import React, {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './logo-rectangle.png'; 
import picImage       from './Picture_Rub_al_Khali.jpg';
import picImageJoan   from './Picture_Joan_Daemen.png';
import picImageGregor from './Picture_Gregor_Leander.jpg';
import picImageYu from './Picture_Yu_Sasaki.jpg';

function TabPanel(props) {
  const { children, value, index } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className='main'
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function App() {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:800px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setValue(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const logoStyle = {
    backgroundImage: `url(${logo})`, 
    backgroundSize: '120px', 
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '120px',
  };

  const imageStyle = {
    backgroundImage: `url(${picImage})`, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    width:  'auto',
    height: '500px',
  };
  
  const imageStyleJoan = {
    backgroundImage: `url(${picImageJoan})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    width:  '300px',
    height: '400px',
  };

  const imageStyleGregor = {
    backgroundImage: `url(${picImageGregor})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    width:  '300px',
    height: '400px',
  };
  
  const imageStyleYu = {
    backgroundImage: `url(${picImageYu})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    width:  '300px',
    height: '400px',
  };

  return (
    <Paper elevation={3} className="App">
      <Grid container rowSpacing="20px" direction="row" className='Tab' position="fixed" height="67px">        
        <Grid item 
          xs={4} 
      //  style={logoStyle} 
          margin="25px 0 0 0"  
          textAlign="center"
          onClick={()=>{setValue(0)}}
        />
        {isMobile ? (
          <Grid item xs={7} textAlign="right" margin="10px 0 0 0" >
            <MenuIcon 
              onClick={handleMenuClick}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {['About', 'Submission', 'Keynotes', 'Committee', 'Program', 'Contact'].map((tab, index) => (
                <MenuItem key={tab} onClick={() => handleMenuItemClick(index)}>
                  {tab}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          <Grid item xs={8} margin-left="10%">
            <Tabs value={value}
              onChange={handleChange}
              centered={!isMobile}
              variant={isMobile ? "scrollable" : "standard"} 
              allowScrollButtonsMobile
              scrollButtons={isMobile ? "auto" : "off"} 
            >
              <Tab label="About"/>
              <Tab label="Submission"/>
              <Tab label="Keynotes"/> 
              <Tab label="Committee"/> 
              <Tab label="Program"/> 
              <Tab label="Contact"/>
            </Tabs>
          </Grid>
        )}
      </Grid> 
      <Grid container style={imageStyle}>
        <Grid item textAlign="center" xs={12} margin={isMobile ? "100px 0 0 0":"150px 0 0 10px"}>
        <Typography textAlign="center" style={{ fontWeight: 'bold', fontSize:'48px', color: 'black'}}>
            Low-Latency Encryption
          </Typography>        
          <Typography textAlign="center" style={{ fontWeight: 'bold', fontSize:'48px', color: 'black'}}>
            (LLE 2024)
          </Typography>        
          <Typography textAlign="center" style={{ fontWeight: 'bold', fontSize:'24px', color: 'black'}}>
            Affiliated with ACNS Conference
          </Typography>
          <Typography textAlign="center" style={{ fontWeight: 'bold', fontSize:'24px', color: 'black'}}>
            March 5-8, 2024 - Abu Dhabi, UAE
          </Typography>
        </Grid>
      </Grid>
      <Paper className='main' >
        <TabPanel value={value} index={0}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Call for Papers
            </Typography>
          </Grid>          
          <Grid container padding="5px 0 0 10px">
            <Grid item xs={12} padding={isMobile ? "0 10% 0 10%":"0 300px 0 300px"}>
              <Typography style={{ textAlign:"justify"}}>
                In recent years, the demand for cryptographic primitives with minimum execution time in hardware implementation has significantly increased. 
                Applications such as memory encryption, secure caches, and pointer authentication are good examples for urgency of this research discipline. 
                While designing low-latency primitives is the main performance requirement in these scenarios, they need to keep the other implementation parameters, such as area, power and energy consumption of the circuit, as light as possible. 
                On the other hand, it is necessary that these primitives provide the highest possible cryptographic security while preserving all these implementation constraints. 
                Therefore, it is necessary to investigate the possible trade-offs between the hardware implementation parameters and the security provided by or for such designs.
                <br/>
                The aim of this workshop is to provide an international forum for researchers to explore and further push the boundaries for this young and emerging research field. 
                We welcome submissions on the application, improvement and efficient implementation, security analysis and design of these primitives.
                <br/>
                <br/>
                To encourage greater student participation, ACNS 2024 organizers offers travel grants for students.
                More details about these grants can be found on ACNS 2024 Student Travel Grants <a href="https://wp.nyu.edu/acns2024/student-travel-grants/" target="_blank">website</a>.
                <br/>
                In addition, there is a best ACNS workshop paper award (with 500 EUR prize sponsored by Springer), to be selected from the accepted papers of all workshops.
              </Typography>
            </Grid>
          </Grid>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Topics and Scope
            </Typography>
          </Grid>
          <Grid container padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 320px"}>
              <li>Applications Requiring Low-Latency Encryption</li>
              <li>Security Requirements for Applications of Low-Latency Encryption</li>
              <li>Techniques for Implementing Low-Latency Cryptographic Primitives</li>
              <li>Cryptographic Modes for Applications of Low-Latency Encryption</li>
              <li>Design and Cryptanalysis of Low-Latency Encryption</li>
            </Grid>
          </Grid>
          <Grid textAlign="center" padding={isMobile ? "4% 10% 0 10%":"50px 0 0 0"} >
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Important Dates
            </Typography>
          </Grid>
          <Grid container padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 320px"}>
              <table>
                <tr>
                    <td>Paper Submission Deadline:</td>
                    <td> </td>
                    <td>extended to <b>10th December, 2023</b></td>
                </tr>
                <tr>
                    <td>Paper Notification:</td>
                    <td> </td>
                    <td>extended to <b>10th January 2024</b></td>
                </tr>
                <tr>
                    <td>Camera-Ready Deadline:</td>
                    <td> </td>
                    <td>extended to <b>25th January, 2024</b></td>
                </tr>
              </table>
            </Grid>
          </Grid>
          <Grid container padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 320px"}>
              <Typography style={{ textAlign:"justify"}}>
                <br/>
                
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Submission Guidelines
            </Typography>
          </Grid>          
          <Grid container padding="5px 0 0 10px">
            <Grid item xs={12} padding={isMobile ? "0 10% 0 10%":"0 300px 0 300px"}>
              <Typography style={{ textAlign:"justify"}}>
                We encourage researchers working on all aspects of <it>Low-Latency Encryption</it> to take the opportunity and use LLE to share their work and participate in discussions.
                The authors are invited to submit the papers using EasyChair submission system through submission link <a href="https://easychair.org/my/conference?conf=lle2024" target="_blank">here</a>.
                <br/>
                Submissions must be anonymous, with no author names, affiliations, acknowledgments, or obvious references. 
                Each submission must begin with a title, short abstract, and a list of keywords. 
                The introduction should summarize the contributions of the paper at a level appropriate for a non-specialist reader.
                <br/>
                All submissions must be submitted in PDF format, following the unmodified LNCS format (accessible at <a href="https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="_blank">here</a>) and typeset using the corresponding LaTeX class file. 
                They must fit within a page limit of 20 pages, including title and abstract, figures, etc., but excluding references. 
                Submissions not meeting these guidelines risk rejection without consideration of their merits.
                To accommodate for changes requested in reviews, the page limit for the camera-ready proceedings versions is 30 pages, including references and appendices.
                <br/>
                Workshop papers will be published by Springer as a volume of the Lecture Notes in Computer Science (LNCS) series, in parallel with the main ACNS conference proceedings.
                See <a href="https://link.springer.com/conference/acns" target="_blank">here</a> for the past workshop proceedings.
                <br/>
                Note that every accepted paper must have at least one author registered for the workshop and 
                there is a best ACNS workshop paper award (with 500 EUR prize sponsored by Springer), to be selected from the accepted papers of all workshops.
                The best workshop paper award is selected from all workshops.
                Each workshop nominates a candidate paper, and the winning paper is selected among them.
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 4% 0":"50px 0 50px 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Keynote Speakers
            </Typography>
          </Grid>
          {isMobile ? (
            <Grid container padding="5px 0 0 10px">
              <Grid container item xs={12} padding="0 10% 15% 10%">
                <Grid item textAlign="center" xs={12}>
                  <div style={imageStyleJoan}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                  <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Joan Daemen
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    Radboud University, The Netherlands.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Joan Daemen is full professor symmetric cryptography at the Radboud University of Nijmegen, the Netherlands. 
                  He is co-designer of the influential Rijndael block cipher, that was selected by NIST as the Advanced Encryption Standard in 2000 and co-designer of the Keccak cryptographic hash function thate was selected as the SHA-3 hash standard by NIST in 2012 and is a founder of permutation-based cryptography.
                  <br/>
                  He has over 20 years of security industry experience, including work as a security architect and cryptographer for STMicroelectronics. 
                  <br/>
                  In 2017 he won the Levchin Prize for Real World Cryptography for "the development of AES and SHA3". 
                  In 2018 he was awarded an ERC advanced grant for research on the foundations of security in symmetric cryptography called ESCADA. 
                </Typography>
              </Grid>
              <Grid container item xs={12} padding="0 10% 15% 10%">
                <Grid item textAlign="center" xs={12}>
                  <div style={imageStyleGregor}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                  <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Gregor Leander
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    Ruhr University Bochum, Germany.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Gregor Leander received his doctorate in cryptography and coding theory at RUB in 2004. 
                  In 2006, he moved to the Université Toulon, France, for one year. 
                  Between 2008 and 2012 he worked as Associate Professor at the Technical University of Denmark. 
                  Since 2015 he is professor at the Ruhr University Bochum in Germany. 
                  His research focuses on the design and the analysis of symmetric primitives and the theory of Boolean functions.
                </Typography>
              </Grid>

              <Grid container item xs={12} padding="0 10% 15% 10%">
                <Grid item textAlign="center" xs={12}>
                  <div style={imageStyleYu}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                  <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Yu Sasaki
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    NTT, Japan and NIST, USA.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Yu Sasaki received Bachelor of Engineering and Master of Engineering from The University of Electro-Communications in 2005 and 2007. 
                  In 2010, he received Ph.D. degrees from The University of Electro-Communications, focusing on the symmetric-key cryptography Since 2007, he has been a researcher at NTT Secure Platform Laboratories, where the organization renewed to NTT Social Informatics Laboratories from 2020. 
                  From 2015 to 2016, he was a senior research fellow at Nanyang Technological University in Singapore. 
                  From 2022, he has been a foreign guest researcher at National Institute of Standards and Technology in US. 
                  His current research interests are in cryptography, including design and security analysis of symmetric-key cryptographic schemes. 
                  He is the co-designer of SKINNY, which is internationally standardized by ISO.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container padding="5px 0 0 10px">
              <Grid container item xs={6} padding="0 30px 0 300px">
                <Grid item xs={12} display="grid" justifyContent="center"  textAlign="center">
                  <div style={imageStyleJoan}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                  <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Joan Daemen
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    Radboud University, The Netherlands.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Joan Daemen is full professor symmetric cryptography at the Radboud University of Nijmegen, the Netherlands. 
                  He is co-designer of the influential Rijndael block cipher, that was selected by NIST as the Advanced Encryption Standard in 2000 and co-designer of the Keccak cryptographic hash function thate was selected as the SHA-3 hash standard by NIST in 2012 and is a founder of permutation-based cryptography.
                  He has over 20 years of security industry experience, including work as a security architect and cryptographer for STMicroelectronics. 
                  In 2017 he won the Levchin Prize for Real World Cryptography for "the development of AES and SHA3". 
                  In 2018 he was awarded an ERC advanced grant for research on the foundations of security in symmetric cryptography called ESCADA. 
                </Typography>
              </Grid>
              <Grid container item xs={6} padding="0 300px 0 30px">
                <Grid display="grid" justifyContent="center" item textAlign="center" xs={12}>
                  <div style={imageStyleGregor}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Gregor Leander
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    Ruhr University Bochum, Germany.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Gregor Leander received his doctorate in cryptography and coding theory at RUB in 2004. 
                  In 2006, he moved to the Université Toulon, France, for one year. 
                  Between 2008 and 2012 he worked as Associate Professor at the Technical University of Denmark. 
                  Since 2015 he is professor at the Ruhr University Bochum in Germany. 
                  His research focuses on the design and the analysis of symmetric primitives and the theory of Boolean functions.
                </Typography>
              </Grid>
              <Grid container item xs={6} padding="60px 30px 0 300px">
                <Grid item xs={12} display="grid" justifyContent="center"  textAlign="center">
                  <div style={imageStyleYu}></div>
                </Grid>
                <Grid item xs={12} textAlign="center" padding="30px 0 10px 0">
                  <Typography style={{ fontWeight: 'bold' , fontSize:'20px'}}>
                    Yu Sasaki
                  </Typography>
                  <Typography style={{ fontStyle: 'italic' , fontSize:'16px'}}>
                    NTT, Japan and NIST, USA.
                  </Typography>
                </Grid>
                <Typography style={{ textAlign:"justify"}}>
                  Yu Sasaki received Bachelor of Engineering and Master of Engineering from The University of Electro-Communications in 2005 and 2007. 
                  In 2010, he received Ph.D. degrees from The University of Electro-Communications, focusing on the symmetric-key cryptography Since 2007, he has been a researcher at NTT Secure Platform Laboratories, where the organization renewed to NTT Social Informatics Laboratories from 2020. 
                  From 2015 to 2016, he was a senior research fellow at Nanyang Technological University in Singapore. 
                  From 2022, he has been a foreign guest researcher at National Institute of Standards and Technology in US. 
                  His current research interests are in cryptography, including design and security analysis of symmetric-key cryptographic schemes. 
                  He is the co-designer of SKINNY, which is internationally standardized by ISO.
                </Typography>
              </Grid>
            </Grid>            
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold', fontSize:'24px'}}>
              Organizing Committee
            </Typography>
          </Grid>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'italic', fontSize:'22px'}}>
              Program Chairs
            </Typography>
          </Grid>
          <Grid textAlign="center" padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 320px"}>
              <b>Shahram Rasoolzadeh</b>, Radboud University, The Netherlands.
              <br/>
              <b>Santosh Ghosh</b>, Intel Labs, USA.
            </Grid>
          </Grid>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'italic', fontSize:'22px'}}>
              Program Committee
            </Typography>
          </Grid>
          <Grid textAlign="center" padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 300px"}>
              <b>Christof Beierle</b>, Ruhr University Bochum, Germany.
              <br/>
              <b>Elif Bilge Kavun</b>, University of Passau, Germany.
              <br/>
              <b>Christoph Dobraunig</b>, Intel Labs, USA.
              <br/>
              <b>Maria Eichlseder</b>, Graz University of Technology, Austria.
              <br/>
              <b>Fukang Liu</b>, Tokyo Institute of Technology, Japan.
              <br/>
              <b>Bart Mennink</b>, Radboud University, The Netherlands.
              <br/>
              <b>Thorben Moos</b>, Universite Catholique de Louvain, Belgium.
              <br/>
              <b>Siwei Sun</b>, University of Chinese Academy of Sciences, China.
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Program
            </Typography>
            <br/>
            The workshop will be held on Thursday 7th March, in the Room 1 in the same venue as ACNS conference. 
          </Grid>
          <Grid container padding="20px 0 0 10px" >
            <Grid item xs={12} padding={isMobile ? "0 10% 0 12%":"0 300px 0 320px"}>
              <table>
                <tr>
                    <td>10:00-10:10</td>
                    <td> </td>
                    <td>Opening Remarks</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                    <td>10:10-10:50</td>
                    <td> </td>
                    <td>Invited Talk by <b>Joan Daemen</b>:</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Building a Low-Latency Pseudorandom Function</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                    <td>10:50-11:10</td>
                    <td> </td>
                    <td>Accepted Paper by <i>Zheng Zhao, Qun Liu, Yanhong Fan, and Meiqin Wang</i></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Construction of 4×4 Lightweight Low-latency Involutory MDS Matrices</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                    <td>11:10-11:30</td>
                    <td> </td>
                    <td>Coffee Break</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                    <td>11:30-12:10</td>
                    <td> </td>
                    <td>Invited Talk by <b>Gregor Leander</b>:</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Designing Low-Latency Primitives and Modes</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                    <td>12:10-12:50</td>
                    <td> </td>
                    <td>Invited Talk by <b>Yu Sasaki</b>:</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>Automated Security Analysis of Symmetric-Key Primitives Using Tools</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
                <tr>
                <td>12:50-13:00</td>
                    <td> </td>
                    <td>Closing</td>
                </tr>
                <tr><td></td><td></td><td></td></tr>
              </table>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Grid textAlign="center" padding={isMobile ? "4% 0 0 0":"50px 0 0 0"}>
            <Typography style={{ fontWeight: 'bold' , fontSize:'24px'}}>
              Contact Information
            </Typography>
          </Grid>          
          <Grid container padding="5px 0 0 10px">
            <Grid item xs={12} padding={isMobile ? "0 15% 0 15%":"0 400px 0 400px"}>
              Shahram Rasoolzadeh
              <br/>
              Radboud University, Digital Security Group,
              <br/>
              Toernooiveld 212
              <br/>
              6525 EC Nijmegen, The Netherlands. 
            </Grid>
          </Grid>
          <Grid container padding="50px 0 0 10px">
            <Grid item xs={12} padding={isMobile ? "0 15% 0 15%":"0 400px 0 400px"}>
              If you have any questions about the workshop please <a href="mailto:shahram.rasoolzadeh@ru.nl?subject=Question%20About%20Low-Latency%20Encryption%20Workshop">contact us</a>.
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
      <Grid container className='end' position="absolute" height="50px">
        <Grid item xs={12} textAlign="center" padding="4px 0 0 0">
          <Typography color="white">
            Design by: <font color="gray">Negar Pouri Tahvildari</font>.
            <br/>
            Photograph by: <font color="gray">Wikipedia user: Nepenthes</font>.
          </Typography> 
        </Grid>
      </Grid>
    </Paper>
  );
}

export default App;